import React from 'react';
import { Switch, Route } from "react-router-dom";
import * as Constants from './Constants'
import logo from './logo.svg';

export default function AppRoute() {
  return (
    <Switch>
      <Route exact path={Constants.routes[0]}>
        <Home />
      </Route>
      <Route path={Constants.routes[1]}>
        <Checkin />
      </Route>
      <Route path={Constants.routes[2]}>
        <Profile />
      </Route>
    </Switch>
  );
}

function Home() {
  return (
    <div>
      <h2>预约</h2>
      <header>
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

function Checkin() {
  return (
    <div>
      <h2>签到</h2>
    </div>
  );
}

function Profile() {
  return (
    <div>
      <h2>我的</h2>
    </div>
  );
}
