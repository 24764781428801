import React from 'react';
import { Link } from "react-router-dom";
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EventIcon from '@material-ui/icons/Event';
import SportsTennisIcon from '@material-ui/icons/SportsTennis';
import PersonIcon from '@material-ui/icons/Person';
import * as Constants from './Constants'
import LocalizedStrings from 'react-localization';

const useStyles = makeStyles({
  stickToBottom: {
    width: '100%',
    position: 'fixed',
    top: 'auto', 
    bottom: 15,
  },
});

const localeStrings = new LocalizedStrings({
  en:{
    booking: 'Booking',
    checkin: 'Checkin',
    profile: 'Me'
  },
  cn: {
    booking: '预约',
    checkin: '签到',
    profile: '我的'
  }
 });

export default function AppNav() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  localeStrings.setLanguage(Constants.language);

  return (
    <BottomNavigation className={classes.stickToBottom} showLabels value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
    >
      <BottomNavigationAction label={localeStrings.booking} icon={<EventIcon />} component={Link} to={Constants.routes[0]} />
      <BottomNavigationAction label={localeStrings.checkin} icon={<SportsTennisIcon />} component={Link} to={Constants.routes[1]} />
      <BottomNavigationAction label={localeStrings.profile} icon={<PersonIcon />} component={Link} to={Constants.routes[2]} />
    </BottomNavigation>
  );
}
